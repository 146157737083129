@import "./src/style/scss/colors";

.table-item-value {
  color: $tableColor;
}

.table-item-value-id {
  color: $payed;
}

.table-item-value-CREATED, .table-item-value-Created, .table-item-value-created {
  color: $created;
}

.table-item-value-PAYED, .table-item-value-Payed, .table-item-value-payed {
  color: $payed;
}

.table-item-value-SUCCESS, .table-item-value-Success, .table-item-value-success {
  color: $payed;
}

.table-item-value-PROCESSED, .table-item-value-processed, .table-item-value-Processed {
  color: $payed;
}

.table-item-value-OK, .table-item-value-ok, .table-item-value-Ok {
  color: $payed;
}

.table-item-value-PROCESSING, .table-item-value-Processing, .table-item-value-processing {
  color: $mainYellow;
}

.table-item-value-QUEUED, .table-item-value-Queued, .table-item-value-queued {
  color: $mainYellow;
}

.table-item-value-FAILED, .table-item-value-Failed, .table-item-value-failed {
  color: $mainRed;
}

.table-item-value-REFUNDED, .table-item-value-Refunded, .table-item-value-refunded {
  color: $mainRed;
}

.table-item-value-CANCELED, .table-item-value-Canceled, .table-item-value-canceled {
  color: $mainRed;
}

.table-item-value-VALIDATION_ERROR, .table-item-value-Validation_error, .table-item-value-validation_error {
  color: $mainRed;
}
