@import "./effects";
@import "./colors";
@import "./text";

@mixin baseInput() {
  background: $inputBackground;
  border: 1px solid $inputBorder;
  border-radius: 5px;
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @include mainText();
  font-family: "Montserrat Regular", sans-serif;
  color: $black;
  padding-left: 20px;
  padding-right: 60px;
  outline: none;

  &:focus {
    border-color: $main;
  }

  &::placeholder {
    color: $black;
  }
}


.login-input {

  input {
    min-height: 50px;
    @include baseInput();
  }
}
