// COLORS
$white: #ffff;
$whiteSecondary: #f9f9f9;
$black: #000000;
$blackSecondary: #222529;
$greenMain: #43B400;
$gray: #646464;
$graySecondary: #62666A;
$grayDark: #333333;
$grayDarkSecondary: #B2B3B3;
$grayMiddle: #DADADA;
$grayLight: #EDEDED;
$grayLightSecondary: #fcfcfc;
$grayLightAdditional: #EAEAEA;
$graySpecial: #E5E5E5;
$grayLightSpecial: #E8E8E8;
$grayLightSpecialAdd: #8E8E93;
$grayAnother: #F4F4F4;
$mainRed: #FF0100;
$darkBlue: #343C47;
$lightBlue: #3F545B;
$mainYellow: #FAEA10;

// NEW STYLE
$main: #F23064;

// BUTTONS
$loginBtnColor: $main;
$disabledBtnColor: $grayMiddle;
$darkBtnColor: $grayDarkSecondary;
$cancelBtnColor: $mainRed;

// INPUTS
$inputText: $gray;
$inputErrorText: $mainRed;
$inputBorder: $grayLightAdditional;
$inputBackground: $white;
$inputBorderFocused: $greenMain;

// CHECKBOX
$checkBoxBorder: $grayLightAdditional;
$checkBoxLabelText: $gray;
$checkBoxBackground: $white;

// ERROR
$errorText: $mainRed;

// BACKGROUND
$secondary: $whiteSecondary;
$light: $grayLightSecondary;

// TEXT
$mainTextBlack: $black;
$titleBlack: $blackSecondary;
$textGreen: $greenMain;
$textYellow: $mainYellow;
$grayText: $graySecondary;
$graySecondaryText: $grayLightSpecialAdd;
$redText: $mainRed;
$navigationText: $darkBlue;
$tableColor: $graySecondary;

// LINK
$linkColor: $main;

// BORDER
$grayBorder: $grayLight;
$grayLightSpecialBorder: $grayLightSpecial;
$graySpecialBorder: $graySpecial;
$grayBorderDark: $grayDark;
$greenBorder: $greenMain;
$tableBorder: $grayAnother;

// NAVIGATION
$navigationBackground: $white;

// STATUS COLOR
$created: $blackSecondary;
$processing: $mainYellow;
$payed: $greenMain;
$failed: $mainRed;
