@import "reset";
@import "buttons";
@import "inputs";
@import "text";
@import "fonts";
@import "status";

body {
  font-family: "Montserrat Regular", sans-serif;
}

#main {
  transition: margin-left .5s;
  scroll-behavior: smooth;
}

.page-title {
  @include pageTitle();
  text-transform: uppercase;
}

.arrow-right {
  transform: rotate(270deg);
}

.arrow-left {
  transform: rotate(90deg);
}
