@import "../fonts/gothic/CenturyGothicRegular.ttf";
@import "../fonts/bauhaus/Bauhaus-Medium.ttf";

@font-face {
  font-family: "Gothic-regular";
  src: local("Gothic"),
  url("../fonts/gothic/CenturyGothicRegular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Bauhaus-regular";
  src: local("Bauhaus"),
  url("../fonts/bauhaus/Bauhaus-Medium.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PT Sans";
  src: local("PT"),
  url("../fonts/ptSans/PTSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PT Sans Bold";
  src: local("PT-bold"),
  url("../fonts/ptSans/PTSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Century Bold";
  src: local("Century-bold"),
  url("../fonts/century/centurygothic_bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat Regular";
  src: local("Montserrat-Regular"),
  url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: local("Montserrat-SemiBold"),
  url("../fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat Bold";
  src: local("Montserrat-Bold"),
  url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat Black";
  src: local("Montserrat-Black"),
  url("../fonts/montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
}
