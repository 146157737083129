@import "./colors";
@import "./effects";
@import "./text";

.login-btn {
  width: 100px;
  height: 50px;
  border-radius: 12px;
  box-sizing: border-box;
  border: 1px solid $loginBtnColor;
  color: $loginBtnColor;
  background: white;
  @include buttonText();

  &:hover {
    @include onGreenBtnHover();
  }
}

.confirm-btn {
  width: 300px;
  padding: 7px 16px 8px 16px; // line height 20 для button text похоже не удачен
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid $loginBtnColor;
  color: $white;
  background: $loginBtnColor;
  transition: .5s;
  @include buttonText();

  &:hover {
    @include onHover();
  }

  &-disabled {

  }
}

.cancel-btn {
  width: 300px;
  height: 50px;
  border-radius: 12px;
  box-sizing: border-box;
  border: 1px solid $cancelBtnColor;
  color: $white;
  background: $cancelBtnColor;
  transition: .5s;
  @include buttonText();

  &:hover {
    @include onHover();
  }

  &-disabled {

  }
}

.no-border-btn {
  background-color: inherit;
  border: unset;
  text-decoration: underline;
  @include smallText();
  width: auto;

  &:hover {
    @include onHover();
  }
}

.filter-btn {
  width: 100%;
  height: 50px;
  border-radius: 0 0;
  box-sizing: border-box;
  border: 1px solid $loginBtnColor;
  color: $white;
  background: $loginBtnColor;
  transition: .5s;
  @include mainText();

  &:hover {
    @include onHover();
  }

  &-disabled {

  }
}
