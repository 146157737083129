@import "./src/style/scss/colors";
@import "./src/style/scss/media";

@mixin mainText() {
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
}

@mixin buttonText() {
  @include mainText();
  font-weight: 600;
}

@mixin mainTextMiddle() {
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;

  @include mobile() {
    font-size: 16px;
    line-height: 20px;
  }
}

@mixin smallText() {
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
}

@mixin pageTitle() {
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 78px;
  font-family: "Montserrat Black", sans-serif;
  color: $titleBlack;

  @include mobile() {
    font-size: 26px;
    line-height: 30px;
  }
}

@mixin subtitle() {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;

  @include mobile() {
    font-size: 20px;
    line-height: 26px;
  }
}

@mixin smallSubtitle() {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

@mixin dashboardTitle() {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

@mixin dashboardNavigation() {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
}

@mixin navigationLink() {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

@mixin mobileNavigationLink() {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
}

@mixin dashboardFooterLinks() {
  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(153, 153, 153, 1);
}

@mixin tableTitle() {
  font-family: "Montserrat Bold", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #F23064;
}

@mixin tableActions() {
  font-family: 'Montserrat Regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

@mixin tableItems() {
  font-family: 'Montserrat Regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

@mixin statusText() {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 1px;
}
